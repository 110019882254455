<template>
  <b-card>
    <div class="custom-container">
      <b-row>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <div class="d-flex flex-column align-items-start">
                <h2 class="card-title">
                  <b>Prestaciones</b>
                </h2>
              </div>
            </template>
            <div>
              <b-button variant="outline-info" class="float-right mt-3 mr-5" @click="exportToExcel">Exportar a Excel</b-button>
            </div>
            <div class="px-5 py-3">
              <div class="row" style="gap: 1rem">
                <div class="col-12 col-md-12">
                  <!-- linar-style cursor glow -->
                  <CardTable :data="dataCards" />
                </div>
                <div class="col-12 col-md-12">
                  <!-- linar-style cursor glow -->
                  <CardTable :data="dataCards2" />
                </div>
                <!--<div class="col-12 col-md">
                  <BtnAddCardTable v-b-modal.nuevoServicio text="Nuevo Servicio" />
                </div> -->
              </div>
            </div>
            <div class="row px-3">
              <div class="col px-0">
                <h4 class="mb-2"><b>Mes:</b></h4>
                <b-select v-model="mesSeleccionado" class="form-control bg-dark personalizado" :options="listaMes" text-field="mes" value-field="id" />
              </div>
              <!-- <div class="col-md-2">
                <h4 class="mb-2"><b>Año:</b></h4>
                <b-select v-model="anioSeleccionado" class="form-control bg-dark personalizado" :options="listaAnios" />
              </div> -->
              <div class="col-1 px-0 mt-3">
                <vs-button primary class="px-2 py-1" @click="filtrar(1)" id="sendform2">
                  <i class="fas fa-search mr-1"></i>
                </vs-button>
              </div>
              <div class="col px-1">
                <h4 class="mb-2"><b>Filtrar x paciente:</b></h4>
                <!-- <b-select @change="filtrar(1)" v-model="paciente" type="text" class="form-control bg-dark personalizado" :options="listaPacientes" text-field="pacienteNombre" /> -->
                <v-select
                  required
                  class="style-chooser"
                  :reduce="(value) => value"
                  v-model="paciente"
                  :options="listaPacientes"
                  label="pacienteNombre"
                  placeholder="-- selecciona un paciente --"
                ></v-select>
              </div>
              <div class="col px-1">
                <h4 class="mb-2"><b>Filtrar x profesional:</b></h4>
                <v-select
                  required
                  class="style-chooser"
                  :reduce="(value) => value"
                  v-model="profesional"
                  :options="listaProfesional"
                  label="profesionalPresupuesto"
                  placeholder="-- selecciona un profesional --"
                ></v-select>
              </div>
              <div class="col px-1">
                <h4 class="mb-2"><b>Filtrar x sucursal:</b></h4>
                <v-select
                  required
                  class="style-chooser"
                  :reduce="(value) => value"
                  v-model="sucursal"
                  :options="listaSucursal"
                  label="profesionalPresupuesto"
                  placeholder="-- selecciona opc. --"
                ></v-select>
              </div>
              <div class="col-1 px-1 mt-3">
                <vs-button primary class="px-2 py-1" @click="filtrar(2)" id="sendform2">
                  <i class="fas fa-search mr-1"></i>
                </vs-button>
              </div>
            </div>
            <!-- table registro servicios-->
            <div class="px-3 mt-3">
              <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                <thead>
                  <tr>
                    <th>Rut</th>
                    <th>Paciente nombre</th>
                    <th>Prestación</th>
                    <th>Valor prestación</th>
                    <th>Valor PrestacionSub</th>
                    <th>Categoría</th>
                    <!-- <th>Estado</th> -->
                    <th class="col-1">Fecha Acción</th>
                    <th>N.item</th>
                    <!-- <th>N. presupuesto</th> -->
                    <!-- <th>Prof. Nombre</th> -->
                    <!-- <th>Sucursal</th> -->
                    <th>Porcentaje</th>
                    <!-- <th>Descripción</th>
                    <th>Fecha Inicio</th>
                    <th>Fecha Fin</th>
                    <th>Estado</th>
                    <th>Finalizar Servicio</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in listaItems" :key="item.id" :style="Number(item.porcentaje) > 0 ? 'font-weight: bold ' : ''">
                    <td>
                      {{ item.rut }}
                    </td>
                    <td>
                      {{ item.pacienteNombre }}
                    </td>
                    <td>
                      {{ item.prestacion }}
                    </td>
                    <td>
                      {{ item.valorPrestacion }}
                    </td>
                    <td>
                      {{ item.valorPrestacionSub.toFixed(0) }}
                    </td>
                    <td>
                      {{ item.categoria }}
                    </td>
                    <!-- <td>
                      {{ item.estado }}
                    </td> -->
                    <td>
                      {{ $moment.tz(item.fFechaAccion, "America/Bogota").format("DD-MM-YY") }}
                    </td>
                    <td>
                      {{ item.numeroItem }}
                    </td>
                    <!-- <td>
                      {{ item.numeroPresupuesto }}
                    </td> -->
                    <!-- <td>
                      {{ item.profesionalNombre }}
                    </td>
                    <td>
                      {{ item.sucursalPpto }}
                    </td> -->
                    <td>
                      {{ item.porcentaje }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- tabla costos material especial -->

            <h3 class="card-title px-3">
              <b>Costos material especial</b>
            </h3>
            <div class="px-3 mt-3">
              <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                <thead>
                  <tr>
                    <th>Rut</th>
                    <th>Paciente nombre</th>
                    <th>Prestación</th>
                    <th>Valor prestación</th>
                    <!-- <th>Valor PrestacionSub</th> -->
                    <!-- <th>Categoría</th> -->
                    <th class="col-1">Fecha Acción</th>
                    <!-- <th>N.item</th>
                    <th>Prof. Nombre</th>
                    <th>Sucursal</th>
                    <th>Porcentaje</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in listaItemsMaterial" :key="item.id">
                    <td>
                      {{ item.idPaciente }}
                    </td>
                    <td>
                      {{ item.paciente }}
                    </td>
                    <td>
                      {{ item.prestacionArea }}
                    </td>
                    <td>
                      {{ item.valor }}
                    </td>
                    <!-- <td>
                      {{ item.estado }}
                    </td> -->
                    <td>
                      {{ $moment.tz(item.fecha, "America/Bogota").format("DD-MM-YY") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- costos de remisión -->
            <h3 class="card-title px-3">
              <b>Costos de remisión</b>
            </h3>
            <div class="px-3 mt-3">
              <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                <thead>
                  <tr>
                    <th>Rut</th>
                    <th>Paciente nombre</th>
                    <th>Prestación</th>
                    <th>Valor prestación</th>
                    <th class="col-1">Fecha Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in listaItemsRemision" :key="item.id">
                    <td>
                      {{ item.idPaciente }}
                    </td>
                    <td>
                      {{ item.paciente }}
                    </td>
                    <td>
                      {{ item.prestacionArea }}
                    </td>
                    <td>
                      {{ item.valor }}
                    </td>
                    <td>
                      {{ $moment.tz(item.fecha, "America/Bogota").format("DD-MM-YY") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- tabla costos laboratorio -->
            <h3 class="card-title px-3">
              <b>Costos de laboratorio</b>
            </h3>
            <div class="px-3 mt-3">
              <table id="datatable_registrolaboratorio" class="table table-bordered table-responsive-md table-striped text-center">
                <thead>
                  <tr>
                    <th>Rut</th>
                    <th>Paciente nombre</th>
                    <th>Prestación</th>
                    <th>Valor prestación</th>
                    <!-- <th>Valor PrestacionSub</th> -->
                    <!-- <th>Profesional</th> -->
                    <th class="col-1">Fecha Acción</th>
                    <!-- <th>N.item</th>
                    <th>Prof. Nombre</th>
                    <th>Sucursal</th>
                    <th>Porcentaje</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in listaLaboratorio" :key="item.id">
                    <td>
                      {{ item.rut }}
                    </td>
                    <td>
                      {{ item.pacienteNombre }}
                    </td>
                    <td>
                      {{ item.prestacion }}
                    </td>
                    <td>
                      {{ item.valorPrestacion }}
                    </td>
                    <!-- <td>
                      {{ item.profesionalNombre }}
                    </td> -->
                    <td>
                      {{ $moment.tz(item.fechaAccion, "America/Bogota").format("DD-MM-YY") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- modal add servicio -->
            <b-modal title="Nuevo Servicio" id="nuevoServicio" size="lg" hide-footer hide-header body-class="body-modal-custom">
              <add_servicio :sharedService="sharedService" />
            </b-modal>
          </card>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { core } from "../../../../config/pluginInit";
// import moment_timezone from "moment-timezone";
// import CardTable from "../../../../components/cardTable/CardTable.vue";
// import CardTable from "@/components/cardTable/CardTableV2.vue";
import CardTable from "@/components/cardTable/CardTableV3.vue";
import * as XLSX from "xlsx";

// import BtnAddCardTable from "../../../../components/btnCardTable/btnAddCardTable.vue";
import add_servicio from "../../RegistroServicios/add_servicio.vue";

// const Swal = require("sweetalert2");
export default {
  name: "registroServicios",
  components: {
    CardTable,
    // BtnAddCardTable,
    add_servicio,
  },
  data() {
    return {
      listaMes: [
        { id: 1, mes: "Enero" },
        { id: 2, mes: "Febrero" },
        { id: 3, mes: "Marzo" },
        { id: 4, mes: "Abril" },
        { id: 5, mes: "Mayo" },
        { id: 6, mes: "Junio" },
        { id: 7, mes: "Julio" },
        { id: 8, mes: "Agosto" },
        { id: 9, mes: "Septiembre" },
        { id: 10, mes: "Octubre" },
        { id: 11, mes: "Noviembre" },
        { id: 12, mes: "Diciembre" },
      ],
      listaAnios: ["2024", "2025", "2026"],
      mesSeleccionado: null,
      anioSeleccionado: null,
      persona: {},
      dataTableInstance: null,
      listaVehiculos: [],
      listaPacientes: [],
      listaProfesional: [],
      listaSucursal: [],
      listaItems: [],
      listaItemsbk: [],
      dataCards: [
        {
          title: "TTOS REALIZADOS, CANCELADOS Y EVOLUCIONADOS",
          value: () => this.listaItems.length,
          iconName: "list",
          color: "rgba(254, 114, 28, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            this.dataTableInstance.search("En Proceso").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "TOTAL TTOS REALIZADOS, CANCELADOS Y EVOLUCIONADOS",
          value: () =>
            this.listaItems
              .reduce((accumulator, currentItem) => {
                const valueToAdd = Number(currentItem.porcentaje) > 0 ? parseFloat(currentItem.valorPrestacionSub) : parseFloat(currentItem.valorPrestacion);
                return accumulator + valueToAdd;
              }, 0)
              .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          iconName: "circle-up",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            this.dataTableInstance.search("Finalizado").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },

        {
          title: "COSTOS DE MATERIAL ESPECIAL",
          value: () => this.listaItemsMaterial.length,
          iconName: "list",
          color: "rgba(254, 114, 28, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            this.dataTableInstance.search("En Proceso").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "TOTAL MATERIAL ESPECIAL",
          value: () =>
            this.listaItemsMaterial
              .reduce((accumulator, currentItem) => {
                const valueToAdd = parseFloat(currentItem.valor);
                return accumulator + valueToAdd;
              }, 0)
              .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          iconName: "circle-down",
          color: "rgba(255, 0, 0, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            this.dataTableInstance.search("En Proceso").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
      ],
      // bloque 2
      dataCards2: [
        {
          title: "COSTOS DE LABORATORIO",
          value: () => this.listaLaboratorio.length,
          iconName: "list",
          color: "rgba(254, 114, 28, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            this.dataTableInstance.search("En Proceso").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "TOTAL COSTOS DE LABORATORIO",
          value: () =>
            this.listaLaboratorio
              .reduce((accumulator, currentItem) => {
                const valueToAdd = parseFloat(currentItem.valorPrestacion);
                return accumulator + valueToAdd;
              }, 0)
              .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          iconName: "circle-down",
          color: "rgba(255, 0, 0, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            this.dataTableInstance.search("Finalizado").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },

        {
          title: "COSTOS DE REMISION",
          value: () => this.listaItemsMaterial.length,
          iconName: "list",
          color: "rgba(254, 114, 28, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            this.dataTableInstance.search("En Proceso").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "TOTAL COSTOS DE REMISION",
          value: () =>
            this.listaItemsRemision
              .reduce((accumulator, currentItem) => {
                const valueToAdd = parseFloat(currentItem.valorPrestacion);
                return accumulator + valueToAdd;
              }, 0)
              .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          iconName: "circle-down",
          color: "rgba(255, 0, 0, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            this.dataTableInstance.search("En Proceso").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "TTOS REALIZADOS NO CANCELADOS POR EL PACIENTE",
          value: () => this.listaItemsMaterial.length,
          iconName: "list",
          color: "rgba(254, 114, 28, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            this.dataTableInstance.search("En Proceso").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "TOTAL TTOS REALIZADOS NO CANCELADOS POR EL PACIENTE",
          value: () =>
            this.listaItemsMaterial
              .reduce((accumulator, currentItem) => {
                const valueToAdd = parseFloat(currentItem.valor);
                return accumulator + valueToAdd;
              }, 0)
              .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          iconName: "circle-down",
          color: "rgba(255, 0, 0, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            this.dataTableInstance.search("En Proceso").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
      ],
      paciente: null,
      profesional: null,
      sucursal: null,
      listaItemsMaterial: [],
      listaLaboratorio: [],
      listaLaboratorioBk: [],
      listaItemsRemision: [],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.listaItems = [];
    // await this.getVehiculosByEmpresa();
    this.mesSeleccionado = new Date().getMonth();
    this.anioSeleccionado = new Date().getFullYear();
    let Periodo = this.obtenerPrimerYUltimoDiaDelMes(this.anioSeleccionado, this.mesSeleccionado);
    this.$isLoading(true);
    if (window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
      window.$("#datatable_registroServicios").DataTable().destroy();
    }
    await this.getData(Periodo.primerDia, Periodo.ultimoDia);

    core.index();

    // this.dataTableInstance = window.$("#datatable_registroServicios").DataTable({
    //   language: {
    //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
    //   },
    // });

    this.$isLoading(false);
  },
  methods: {
    exportToExcel() {
      // Crear las hojas de datos de cada array con títulos y sumatorias
      const sheet1 = this.createSheetWithSum(this.listaItems, "Prestaciones", "valorPrestacion");
      const sheet2 = this.createSheetWithSum(this.listaItemsMaterial, "Material Especial", "valor");
      const sheet3 = this.createSheetWithSum(this.listaLaboratorio, "Costos Laboratorio", "valorPrestacion");

      // Crear un nuevo libro de trabajo
      const wb = XLSX.utils.book_new();

      // Agregar cada hoja de trabajo con el nombre deseado
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet1), "Prestaciones");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet2), "Material Especial");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet3), "Costos Laboratorio");

      // Exportar el archivo Excel
      // XLSX.writeFile(wb, 'datos_separados.xlsx');
      XLSX.writeFile(wb, "Liquidación-" + this.profesional + "-" + this.mesSeleccionado + "-" + this.anioSeleccionado + "-" + this.sucursal + ".xlsx");
    },

    // Función para crear una hoja con sumatoria
    createSheetWithSum(data, title, sumColumn) {
      // Verificar si el array de datos está vacío
      if (!data || data.length === 0) {
        console.warn(`El array ${title} está vacío o no está definido.`);
        return [[title], ["No hay datos disponibles"]]; // Retorna un mensaje si el array está vacío
      }

      const headers = Object.keys(data[0]); // Obtener encabezados de las claves del array
      const sheetData = [];

      // Agregar los encabezados de las columnas
      sheetData.push(headers);

      // Agregar los datos del array
      data.forEach((item) => {
        sheetData.push(headers.map((key) => item[key]));
      });

      // Calcular y agregar la fila de sumatoria
      const sumRow = headers.map((key) => (key === sumColumn ? this.sumColumn(data, key) : ""));
      sheetData.push(["Total", ...sumRow.slice(1)]); // Agregar 'Total' y la sumatoria

      return sheetData;
    },

    // Función para calcular la sumatoria de una columna específica
    sumColumn(data, column) {
      return data.reduce((sum, item) => {
        const value = Number(item[column]); // Convertir a número
        return sum + (isNaN(value) ? 0 : value); // Sumar solo si es un número válido
      }, 0);
    },

    async filtrar(item) {
      if (item == 1) {
        this.paciente = null;
        this.profesional = null;
        this.sucursal = null;
        ////////
        this.listaLaboratorio = [];
        this.listaItemsMaterial = [];
        console.log("filtrando", item, this.nombre);
        this.$isLoading(true);
        //filtrar x mes y año
        let mesSeleccionado = new Date(this.anioSeleccionado + "/" + this.mesSeleccionado + "/01").getMonth();
        let anioSeleccionado = new Date().getFullYear();
        let Periodo = this.obtenerPrimerYUltimoDiaDelMes(anioSeleccionado, mesSeleccionado);
        await this.getData(Periodo.primerDia, Periodo.ultimoDia);

        this.$isLoading(false);
      }
      if (item == 2) {
        console.log(this.paciente);
        if (this.paciente != null && this.profesional == null && this.sucursal == null) {
          console.log(this.paciente);
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente);
        }
        if (this.paciente != null && this.profesional != null && this.sucursal == null) {
          console.log(this.paciente);
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente && x.profesionalNombre == this.profesional);
        }
        if (this.paciente != null && this.profesional != null && this.sucursal != null) {
          console.log(this.paciente);
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente && x.profesionalNombre == this.profesional && x.sucursalPpto == this.sucursal);
        }

        if (this.paciente == null && this.profesional != null && this.sucursal == null) {
          this.listaItems = this.listaItemsbk.filter((x) => x.profesionalNombre == this.profesional);
        }
        if (this.paciente == null && this.profesional != null && this.sucursal != null) {
          this.listaItems = this.listaItemsbk.filter((x) => x.profesionalNombre == this.profesional && x.sucursalPpto == this.sucursal);
          //problema: no existe rut del profesional en prestaciones
          try {
            this.$isLoading(true);

            //filtrando materiales si ha seleccionado profesional y sucursal
            let mesSeleccionado = new Date(this.anioSeleccionado + "/" + this.mesSeleccionado + "/01").getMonth();
            let anioSeleccionado = new Date().getFullYear();
            let Periodo = this.obtenerPrimerYUltimoDiaDelMes(anioSeleccionado, mesSeleccionado);

            // material especial
            await this.obtenerMateriales(Periodo.primerDia, Periodo.ultimoDia, "000", this.sucursal);
            console.log("listaItemsMaterial", this.listaItemsMaterial);
            //let idProfesional = this.listaItems.filter((x) => x.profesionalNombre == this.profesional);

            let listaItemsMaterial = this.listaItemsMaterial.filter((x) => {
              const normalize = (nombre) => nombre.trim().toLowerCase().replace(/\s+/g, " ").replace(",", "");

              const nombreProfesional = normalize(this.profesional);
              const nombreItem = normalize(x.profesional);
              console.log("profesional data 0", nombreProfesional);
              console.log("profesional data 1", nombreItem);
              // Función para comparar dos nombres sin importar el orden
              const sonIguales = (n1, n2) => {
                const arr1 = n1.split(" ").sort();
                const arr2 = n2.split(" ").sort();
                return JSON.stringify(arr1) === JSON.stringify(arr2);
              };

              return sonIguales(nombreItem, nombreProfesional);
            });
            for await (let itm of listaItemsMaterial) {
              itm.valor = parseFloat(itm.costoUnitarioProm) * 1.2;
            }
            this.listaItemsMaterial = listaItemsMaterial;

            //costos de remision
            this.listaItemsRemision = listaItemsMaterial.map((item) => ({
              ...item,
              valorPrestacion: 70000,
            }));
            this.$forceUpdate();
            console.log("this.listaItemsRemision", this.listaItemsRemision);
            // obtener data laboratorio
            await this.obtenerLaboratorio(Periodo.primerDia, Periodo.ultimoDia, this.sucursal);

            let listaLaboratorio = this.listaLaboratorioBk.filter((x) => {
              const normalize2 = (nombre2) => nombre2.trim().toLowerCase().replace(/\s+/g, " ").replace(",", "");
              // console.log("EspecialistaOcTrabLaborator", x.especialistaOcTrabLaborator);
              const nombreProfesional2 = normalize2(this.profesional);
              // const nombreItem2 = normalize2(x.profesionalNombre.split("-")[1]);
              const nombreItem2 = normalize2(x.profesionalNombre);
              // console.log("EspecialistaOcTrabLaborator data 0", nombreProfesional);
              console.log("EspecialistaOcTrabLaborator data 1", nombreItem2);
              // Función para comparar dos nombres sin importar el orden
              const sonIguales2 = (n12, n22) => {
                const arr12 = n12.split(" ").sort();
                const arr22 = n22.split(" ").sort();
                return JSON.stringify(arr12) === JSON.stringify(arr22);
              };

              return sonIguales2(nombreItem2, nombreProfesional2);
            });
            this.listaLaboratorio = listaLaboratorio;
            console.log("EspecialistaOcTrabLaborator", listaLaboratorio);

            this.$forceUpdate();
            this.$isLoading(false);
          } catch (error) {
            this.$isLoading(false);
          }
          //console.log("profesional data", this);
          // idProfesional = idProfesional[0].rut;
          // console.log("profesional data", idProfesional);
        }

        if (this.paciente == null && this.profesional == null && this.sucursal != null) {
          this.listaItems = this.listaItemsbk.filter((x) => x.sucursalPpto == this.sucursal);
        }
        if (this.paciente != null && this.profesional == null && this.sucursal != null) {
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente && x.sucursalPpto == this.sucursal);
        }
      }
      // this.$nextTick(function () {
      //   if (!window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
      //     window.$("#datatable_registroServicios").DataTable();
      //   }
      // });
      // this.$nextTick(function () {
      //   window.$("#datatable_registroServicios").DataTable();
      // });
    },
    async obtenerLaboratorio(primerDia, ultimoDia, sucursal) {
      this.listaLaboratorio = [];
      this.listaLaboratorioBk = this.listaLaboratorio;
      let res = await this.$store.dispatch("denti_get", {
        path: "SIE_Laboratorio/ListaLaboratorio/" + primerDia + "/" + ultimoDia + "/" + sucursal,
      });
      console.log("res dentisalud ", res);
      this.listaLaboratorio = res.length > 0 ? res : [];
      this.listaLaboratorioBk = this.listaLaboratorio;
    },
    async obtenerMateriales(primerDia, ultimoDia, rutProfesional, Sucursal) {
      this.listaItemsMaterial = [];
      //buscar el idprofesional en this.listaItems

      let res = await this.$store.dispatch("denti_get", {
        path: "VMaterialesEspeciales/ListVMaterialesEspeciales/" + primerDia + "/" + ultimoDia + "/" + Sucursal + "/" + rutProfesional,
      });
      console.log("res dentisalud ", res);
      this.listaItemsMaterial = res.length > 0 ? res : [];
    },
    obtenerPrimerYUltimoDiaDelMes(anio, mes) {
      // El mes en JavaScript es base 0, por lo que enero es 0 y diciembre es 11
      const primerDia = new Date(anio, mes, 1);
      const ultimoDia = new Date(anio, mes + 1, 0); // El día 0 del mes siguiente es el último día del mes actual

      return {
        primerDia: primerDia.toISOString().split("T")[0], // Formato YYYY-MM-DD
        ultimoDia: ultimoDia.toISOString().split("T")[0],
      };
    },
    async getData(primerDia, ultimoDia) {
      try {
        if (window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
          window.$("#datatable_registroServicios").DataTable().destroy();
        }
        this.listaItems = [];
        // console.log("getdata dentisalud", Periodo);
        let res = await this.$store.dispatch("denti_get", {
          path: "Prestaciones/ListaPrestaciones/" + primerDia + "/" + ultimoDia,
        });
        console.log("res dentisalud ", res);
        this.listaItems = res.length > 0 ? res : [];
        if (this.listaItems.length > 0) {
          this.listaItems = this.listaItems.map((item) => ({
            ...item,
            valorPrestacion: Number(item.valorPrestacion) - Number(item.valorPrestacion) * Number(item.porcentaje),
            valorPrestacionSub: Number(item.valorPrestacion) - Number(item.valorPrestacion) * Number(item.porcentaje),
          }));
        }
        this.listaItemsbk = this.listaItems;

        this.listaPacientes = res.map((p) => p.pacienteNombre).filter((nombre, index, self) => self.indexOf(nombre) === index);

        console.log("listapacientes", this.listaPacientes);

        this.listaPacientes.sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });

        this.listaProfesional = res.map((pa) => pa.profesionalNombre).filter((nombrep, indexp, selfp) => selfp.indexOf(nombrep) === indexp);
        this.listaProfesional.sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
        this.listaSucursal = res.map((pa) => pa.sucursalPpto).filter((nombrep, indexp, selfp) => selfp.indexOf(nombrep) === indexp);
        this.listaSucursal.sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });

        // console.log("pacientes ", this.listaPacientes);
        // console.log("list registroServicios...", res);
        // if (res.success) {
        //   this.listaItems = res.data.map((service) => {
        //     return {
        //       ...service,
        //       placa: this.getPlacaVehiculo(service.placaId),
        //       estado: service.fechaFin ? true : false,
        //     };
        //   });
        //   // console.log(this.listaItems);
        // } else {
        //   this.listaItems = [];
        // }
        this.$isLoading(false);

        // this.$nextTick(function () {
        //   if (!window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
        //     window.$("#datatable_registroServicios").DataTable();
        //   }
        // });

        // core.index();

        // if (window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
        //   window.$("#datatable_registroServicios").DataTable().destroy();
        // }
        // this.dataTableInstance = window.$("#datatable_registroServicios").DataTable({
        //   language: {
        //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        //   },
        // });
        // this.$forceUpdate();
      } catch (error) {
        this.$isLoading(false);

        this.listaItems = [];
        console.log("err dentisalud ", error);
      }
    },
  },
};
</script>

<style scoped></style>
